/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import { concatMap, delay, filter, map, mergeMap, retryWhen } from 'rxjs/operators';
import { LoginService } from './login.service';
import { AUTHENTICATED, ERROR_REASON, getReason } from '@core/http';
import { WHITE_LISTED_DOMAINS } from './auth.constants';

export const RETRY_COUNT = 2;
export const RETRY_WAIT_MILLISECONDS = 500;
export const ERROR_CODES_TO_RETRY = [HttpStatusCode.Unauthorized];
@Injectable()
/* eslint-disable */
export class AuthInterceptor implements HttpInterceptor {
  constructor(public auth: LoginService, public router: Router) {}

  private requestQueue = new Map<string, HttpRequest<any>>();

  private get isAuthRefreshInProgress() {
    return this.auth.refreshInProgress;
  }

  private logoutAndRedirect() {
    this.auth.logout();
  }

  private getAuthRequest(req: HttpRequest<any>) {
    console.log('building auth request with', this.auth.idToken);
    return req.clone({
      url: req.url,
      setHeaders: {
        Authorization: `Bearer ${this.auth.idToken}`,
      },
    });
  }

  private retryQueuedRequests(next: HttpHandler) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_key, value] of this.requestQueue) {
      console.log('retry request', _key);
      next.handle(this.getAuthRequest(value));
    }

    console.log('retry requests finished');
    this.requestQueue = new Map();
  }

  private refreshToken(
    error: HttpErrorResponse,
    next: HttpHandler,
  ): Observable<any> {
    console.log('try refreshing token');
    if (this.isAuthRefreshInProgress.getValue()) {
      console.log('refresh is already in progress');
      return of();
    }

    return this.performTokenRefresh(error, next);
  }

  private performTokenRefresh(
    error: HttpErrorResponse,
    next: HttpHandler,
  ): Observable<any> {
    console.log('perform token refresh');
    return from(this.auth.tryToRefreshToken()).pipe(
      map((isRefreshedSuccessfully) => {
        console.log('refresh success', isRefreshedSuccessfully);
        if (!isRefreshedSuccessfully) {
          console.log('logout and redirect');
          this.logoutAndRedirect();
          return of();
        }
        console.log('retry failed requests');
        this.retryQueuedRequests(next);

        return of({ ...error, error: { reason: ERROR_REASON.RETRY } });
      }),
    );
  }

  private checkAuthorisation(
    authRequest: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse,
    reason: ERROR_REASON | string | undefined,
  ) {
    console.log('check auth reason', reason);
    if (reason === ERROR_REASON.TOKENREFRESH) {
      console.log('is token refresh');
      if (this.isAuthRefreshInProgress.getValue()) {
        if (!this.requestQueue.has(authRequest.url)) {
          this.requestQueue.set(authRequest.url, authRequest);
          console.log('added request to queue', authRequest.url);
        }
      }
      return this.refreshToken(error, next);
    }
    console.log('unknown refresh reason, logout and redirect');
    this.logoutAndRedirect();
    return throwError(error);
  }

  private isDomainInWhiteLists(req: HttpRequest<any>) {
    let whiteListed = false;

    for (const domain of WHITE_LISTED_DOMAINS) {
      if (req.url.includes(domain)) {
        whiteListed = true;
        break;
      }
    }

    return whiteListed;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const getAnonimusUrlRegexp = /get-anonymous-token/;
    if (getAnonimusUrlRegexp.test(req.url)) {
      return next.handle(req);
    }

    return this.auth.isReady.pipe(
      filter((isReady: boolean) => {
        console.log('int is ready', isReady);
        return isReady === true;
      }),
      mergeMap(() => {
        if (!this.auth.isTokenValidByTime) {
          console.log('Token is not valid by time');
          return this.checkAuthorisation(
            req,
            next,
            new HttpErrorResponse({}),
            ERROR_REASON.TOKENREFRESH,
          );
        }

        const isAuthenticated = req.context.get(AUTHENTICATED);
        console.log('is authenticated', isAuthenticated);

        if (!isAuthenticated || !this.isDomainInWhiteLists(req)) {
          return next.handle(req);
        }
        const request: HttpRequest<any> = this.getAuthRequest(req);
        return next.handle(request).pipe(
          retryWhen((error: Observable<HttpErrorResponse>) =>
            error.pipe(
              concatMap((error, count) => {
                const isHttpError = error instanceof HttpErrorResponse;
                const reason = getReason(error);

                console.log('error http', error.status, isHttpError, reason);

                if (!this.auth.isTokenValidByTime) {
                  console.log('token is not valid by time');
                  return this.checkAuthorisation(
                    req,
                    next,
                    error,
                    ERROR_REASON.TOKENREFRESH,
                  );
                }

                if (error.status === HttpStatusCode.Unauthorized && reason === ERROR_REASON.TOKENREFRESH) {
                  console.log('error requires token refresh');
                  return this.performTokenRefresh(error, next);
                }

                if (!isHttpError) {
                  console.log('not an http error');
                  return throwError(error);
                }

                const shouldRetry =
                  count <= RETRY_COUNT &&
                  ERROR_CODES_TO_RETRY.includes(error.status) &&
                  !reason;

                console.log('should retry', shouldRetry, count, RETRY_COUNT);

                if (shouldRetry) {
                  return of(error);
                }

                if (error.status === HttpStatusCode.Unauthorized) {
                  if (count > RETRY_COUNT) {
                    console.log('reload fallback');
                    location.reload();
                  } else {
                    console.log('check auth');
                    return this.checkAuthorisation(req, next, error, reason);
                  }
                }
                console.log('int fin, throw err');
                return throwError(error);
              }),
              delay(RETRY_WAIT_MILLISECONDS),
            ),
          ),
        );
      }),
    );
  }
}
